
  import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {},
  methods: {},
  computed: {},
})
  export default class ViewUnderConstruction extends Vue {

  }
